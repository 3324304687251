/**
 * Layout component that queries for data
 * with Gatsby's useStaticQuery component
 *
 * See: https://www.gatsbyjs.org/docs/use-static-query/
 */

import React from "react"
import PropTypes from "prop-types"
import { useStaticQuery, graphql, Link } from "gatsby"

import "./layout.css"
import Styles from "./layout.module.css"

import Navigation from "./Navigation/Navigation"

const Layout = ({ children }) => {
  const data = useStaticQuery(graphql`
    query SiteTitleQuery {
      site {
        siteMetadata {
          title
        }
      }
    }
  `)

  return (
    <>
      <div className={Styles.container}>
        <Navigation
          items={[
            {
              id: "start",
              name: "Start",
              link: "/",
            },
            {
              id: "services",
              name: "Leistungen",
              link: "#leistungen",
            },
            {
              id: "history",
              name: "Historie",
              link: "#historie",
            },
            {
              id: "contact",
              name: "Kontakt",
              link: "#kontakt",
            },
          ]}
        />
        <main className={Styles.mainContent}>
          {children}
        </main>
        <footer className={Styles.footer}>
            <Link to="/impressum">Impressum</Link>
            <Link to="/datenschutz">Datenschutz</Link>
        </footer>
      </div>
    </>
  )
}

Layout.propTypes = {
  children: PropTypes.node.isRequired,
}

export default Layout
