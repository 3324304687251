import React, { useState, useCallback } from "react"
import PropTypes from "prop-types"
import Scrollspy from "react-scrollspy"
import { Link } from "gatsby"

import logo from "../../images/knoepke-bauunternehmen.gif"
import TelefonIcon from "../../images/telefon.png"
import MailIcon from "../../images/mail.png"

import Styles from "./Navigation.module.css"

const Navigation = ({ items }) => {
    const [showNav, setShowNav] = useState(false)

    const toggleNav = useCallback(() => {
        setShowNav(nav => !nav)
    }, [setShowNav]);

    return (
      <nav className={Styles.navbarwrapper}>
        <div className={Styles.navbar}>
          <div className={Styles.logowrapper}>
            <Link to="/">
              <img className={Styles.logo} src={logo} />
            </Link>
          </div>
          <button className={Styles.iconbars} onClick={toggleNav}>
            <span>
            <span className={Styles.iconbarwrapper}>
              <span className={Styles.iconbartop}></span>
              <span className={Styles.iconbar}></span>
              <span className={Styles.iconbar}></span>
            </span>
            <span className={Styles.iconbartext}>Menü</span>
            </span>
          </button>
          <div className={Styles.menu}>
            <Scrollspy
              items={["start", "leistungen", "historie", "kontakt"]}
              currentClassName={Styles.iscurrent}
            >
              {items.map(item => (
                <li key={`navitem-${item.id}`} className={`${Styles.navitem} ${Styles.navhover}`}>
                  <Link to={item.link}>{item.name}</Link>
                </li>
              ))}
              <li className={Styles.navitem}>
                <a href="tel:+495225859771">
                  <img className={Styles.navicon} src={TelefonIcon} />
                </a>
              </li>
              <li className={Styles.navitem}>
                <a href="mailto:info@knoepke-bau.de">
                  <img className={Styles.navicon} src={MailIcon} />
                </a>
              </li>
            </Scrollspy>
          </div>
        </div>
        <div className={`${showNav ? "block" : "hidden"}`}>
          <ul className={Styles.burgermenu}>
            {items.map(item => (
              <a key={`navitem-${item.id}`} className={Styles.navlink} href={item.link}>
                <li className={Styles.navitem}>
                    {item.name}
                </li>
              </a>
            ))}
            <li className={Styles.navitem}>
              <a href="tel:+495225859771">
                <img className={Styles.navicon} src={TelefonIcon} />
              </a>
            </li>
            <li className={Styles.navitem}>
              <a href="mailto:info@knoepke-bau.de">
                <img className={Styles.navicon} src={MailIcon} />
              </a>
            </li>
            <li className={Styles.navitem}>
              <button onClick={toggleNav}>&times; Schließen</button>
            </li>
          </ul>
        </div>
      </nav>
    )
}

Navigation.propTypes = {
    items: PropTypes.arrayOf(
        PropTypes.shape({
            id: PropTypes.string.isRequired,
            name: PropTypes.string.isRequired,
            link: PropTypes.string.isRequired,
        })
    ).isRequired,
}

export default Navigation