import PropTypes from "prop-types"
import React from "react"

import Styles from "./Section.module.css"

const Section = ({ className, children }) => {
    return (
        <section className={`${className}`}>
            <div className={`${Styles.section}`}>{children}</div>
        </section>
    )
}

Section.propTypes = {
}

export default Section